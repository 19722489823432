<template>
  <div>
    <general-table
      ref="reportTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'product-categories',
      addType: 'Add Product',
      addComponentName: 'add-product',
      editComponent: 'edit-product',
      viewContent: false,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'name' },
        { key: 'department.title.en', label: 'Department En' },
        { key: 'department.title.ar', label: 'Department Ar' },
        { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'product-categories?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style></style>
